import React, { useContext } from 'react'
import './Services.css'
import HeartEmoji from '../../img/heartemoji.png'
import Glasses from '../../img/glasses.png'
import Humble from '../../img/humble.png'
import Card from '../Card/Card'
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import Resume from './resume.pdf';

const Services = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };
  return (
    <div className="services">
        <div className="awesome">
            <span style={{ color: darkMode ? "white" : "" }}>My Awesome</span>
            <span>Services</span>
            <span>I provide leadership in the mobile application development process, delivering report, planning,designing and developing user flow.
                I also do overall development and implementation of website application and back-end systems.</span>

            <a href={Resume} download>
            <button className='button s-button'>Download CV</button>
            </a>
            <div className='blur s-blur' style={{background: '#ABF1FF94'}}></div>
        </div>
        <div className="cards">
            <motion.div 
            initial={{ left: "25rem" }}
            whileInView={{ left: "14rem" }}
            transition={transition}
            >
              <Card
              emoji = {HeartEmoji}
              heading = {'Android'}
              detail = {'Kotlin, Java'}
              />

            </motion.div >
            <motion.div 
            initial={{ left: "-11rem", top: "12rem" }}
            whileInView={{ left: "-4rem" }}
            transition={transition}
            >
              <Card
              emoji={Glasses}
              heading={'Website Developer'}
              detail={'React, Node'}
              />
            </motion.div>
            <motion.div
             initial={{ top: "19rem", left: "25rem" }}
             whileInView={{ left: "12rem" }}
             transition={transition} 
            >
              <Card
              emoji={Humble}
              heading={'Design'}
              detail={'Adobe Photoshop, Adobe Illustrator'}
              />
            </motion.div>
            <div className='blur s-blur2' style={{
              background: 'var(--purple)'
            }}>

            </div>
        </div>
    </div>
  )
}

export default Services